<template>
  <b-container class="contact-container">
    <b-row align-h="center" class="contact-main px-0" v-if="!isMobile">
      <b-col class="contact-text py-5 px-5">
        <h3>Contact Me</h3>
        <hr class="horizontal-rule" />
        <h5>Let's get in touch</h5>
        <p>
          Please reach out to me, I would love to work with you, and help you
          achieve your goals.
        </p>
        <p>
          I do get quite busy at times, so please be patient while I respond.
        </p>
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group
              id="input-group-1"
              label-for="input-1"
              description="We'll never share your email with anyone else."
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="Enter email*"
                required
                :state="emailState"
                ref="email"
              ></b-form-input>

              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback>
                Your email address is required
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-2" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.name"
                placeholder="Enter name*"
                required
                :state="nameState"
              ></b-form-input>

              <b-form-invalid-feedback>
                Your name is required
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-3" label-for="input-3">
              <b-form-textarea
                id="input-3"
                v-model="form.message"
                placeholder="Message*"
                rows="3"
                max-rows="6"
                required
                :state="messageState"
              ></b-form-textarea>

              <b-form-invalid-feedback>
                A message of intent/query is required
              </b-form-invalid-feedback>
            </b-form-group>

            <b-button
              class="mx-2 submit-button"
              @click="onSubmit()"
              :disabled="!submitState"
              >Submit</b-button
            >
            <b-button class="mx-2" type="reset" variant="danger"
              >Reset</b-button
            >
          </b-form>
        </div>
      </b-col>
      <b-col class="contact-map">
        <iframe
          class="map"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://www.openstreetmap.org/export/embed.html?bbox=146.57993316650393%2C-36.25285633681968%2C147.25284576416018%2C-35.907961625589316&amp;layer=mapnik&amp;marker=-36.080639391769395%2C146.91627949999997"
        >
        </iframe>
      </b-col>
    </b-row>
    <b-row align-h="center" class="m-contact-main px-0" v-else>
      <b-col class="contact-text py-3 px-5">
        <h3>Contact Me</h3>
        <hr class="horizontal-rule" />
        <h5>Let's get in touch</h5>
        <p>
          Please reach out to me, I would love to work with you, and help you
          achieve your goals.
        </p>
        <p>
          I do get quite busy at times, so please be patient while I respond.
        </p>
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group
              id="input-group-1"
              label-for="input-1"
              description="We'll never share your email with anyone else."
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                placeholder="Enter email*"
                required
                :state="emailState"
                ref="email"
              ></b-form-input>

              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback>
                Your email address is required
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-2" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.name"
                placeholder="Enter name*"
                required
                :state="nameState"
              ></b-form-input>

              <b-form-invalid-feedback>
                Your name is required
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-3" label-for="input-3">
              <b-form-textarea
                id="input-3"
                v-model="form.message"
                placeholder="Message*"
                rows="3"
                max-rows="6"
                required
                :state="messageState"
              ></b-form-textarea>

              <b-form-invalid-feedback>
                A message of intent/query is required
              </b-form-invalid-feedback>
            </b-form-group>

            <b-button
              class="mx-2 submit-button"
              @click="onSubmit()"
              :disabled="!submitState"
              >Submit</b-button
            >
            <b-button class="mx-2" type="reset" variant="danger"
              >Reset</b-button
            >
          </b-form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import * as _core from "../services/coreService";

export default {
  name: "vContact",
  components: {},
  data() {
    return {
      isMobile: false,
      form: {
        name: "",
        email: "",
        message: "",
      },
      show: true,
    };
  },
  computed: {
    emailState() {
      return this.form !== null && !this.isEmptyOrWhiteSpace(this.form.email);
    },
    messageState() {
      return this.form !== null && !this.isEmptyOrWhiteSpace(this.form.message);
    },
    nameState() {
      return this.form !== null && !this.isEmptyOrWhiteSpace(this.form.name);
    },
    submitState() {
      return this.emailState && this.messageState && this.nameState;
    },
  },
  methods: {
    onSubmit() {
      if (
        this.isEmptyOrWhiteSpace(this.form.message) ||
        this.isEmptyOrWhiteSpace(this.form.email) ||
        this.isEmptyOrWhiteSpace(this.form.name)
      ) {
        return;
      }
      if (!this.validateEmail(this.form.email)) {
        alert("Invalid Email. Please properly form your email address.");
        return;
      }
      _core.emailSend(this.createEmail()).then((response) => {
        if (!response.success) {
          alert("Unable to send message.");
        } else {
          alert("Message Sent!");
          this.baseReset();
        }
      });
    },
    createEmail() {
      var email = {
        personalizations: [
          {
            to: [
              {
                name: "Pernickety Contact Form",
                email: "contact@jpernickety.com",
              },
            ],
            subject: `Photography Enquiry`,
          },
        ],
        from: {
          name: this.form.name,
          email: `moule.elliot@gmail.com`,
        },
        content: [
          {
            type: `text/html`,
            value: this.createMessage(this.form.message),
          },
        ],
      };
      return email;
    },
    onReset(event) {
      event.preventDefault();
      this.baseReset();
    },
    baseReset() {
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.message = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    isEmptyOrWhiteSpace(value) {
      return !value || !value.trim();
    },
    validateEmail(email) {
      const re = /^\S+@\S+$/;
      return re.test(String(email).toLowerCase());
    },
    createMessage(userInput) {
      var message =
        "<b>You have received a message from " +
        this.form.name +
        "!</b><br><br>";

      message += userInput + "<br><br>";

      message +=
        "Reply to " +
        this.form.name +
        " (" +
        this.form.email +
        ")?<br><a href='" +
        "mailto:" +
        this.form.email +
        "'>Reply</a>";

      return message;
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.email.$el.focus();
      });
    },
  },
  created() {
    _core.setIsWait(false);
    this.isMobile = _core.getIsMobile();
  },
  mounted() {
    this.focusInput();
  },
};
</script>

<style scoped>
.contact-container {
  background-color: rgb(48, 47, 47) !important;
  padding: 0;
  margin: 0;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
.contact-main {
  width: auto;
  background-color: rgb(27, 27, 27) !important;
}
.m-contact-main {
  width: auto;
}
.map {
  width: 100%;
  height: 100%;
}
.contact-map {
  width: 100%;
  padding: 0;
  margin: 0;
}
.contact-text {
  color: white;
  text-align: justify;
}
.horizontal-rule {
  background-color: white;
}
.submit-button {
  background-color: var(--success);
}
</style>
