var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pageTop",staticClass:"portfolio-main"},[_vm._l((_vm.galleryImages),function(image,index){return _c('div',{key:index,staticClass:"box"},[_c('div',{staticClass:"boxInner"},[_c('img',{staticClass:"coverDiv",style:({
          backgroundImage:
            'url(' +
            (image.smallUrl && !_vm.isEmptyOrSpace(image.smallUrl)
              ? image.smallUrl
              : image.url) +
            ')',
        }),on:{"click":function($event){return _vm.clickImage(image.name)}}})])])}),_c('b-modal',{attrs:{"centered":"","no-stacking":"","ok-only":"","hide-footer":"","hide-header":"","size":"xl"},model:{value:(_vm.modalDialog.show),callback:function ($$v) {_vm.$set(_vm.modalDialog, "show", $$v)},expression:"modalDialog.show"}},[_c('b-card-img',{attrs:{"src":_vm.modalDialog.img}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }