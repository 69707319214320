<template>
  <div class="main-policy" v-if="!isMobile">
    <h2>Privacy Policy</h2>
    <hr />
    <div class="sub-policy">
      <div class="policy-text">
        <h5>- Updated February, 2021</h5>
        <div
          v-for="(item, index) in info"
          :key="index"
          style="padding: 0 0 0 50px"
        >
          <p v-if="!item.header" :style="{ textAlign: item.style }">
            {{ item.text }}
          </p>
          <h4 v-else style="text-align: left !important">{{ item.text }}</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="m-main-policy" v-else>
    <h2>Privacy Policy</h2>
    <hr />
    <div class="m-policy-text">
      <h5>- Updated February, 2021</h5>
      <div v-for="(item, index) in info" :key="index">
        <p v-if="!item.header" :style="{ textAlign: item.style }">
          {{ item.text }}
        </p>
        <h4 v-else style="text-align: left !important">{{ item.text }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import * as _core from "../services/coreService";

export default {
  name: "vPrivacyPolicy",
  components: {},
  data() {
    return {
      isMobile: false,
      portraitImage:
        "https://jimjamphotographystorage.blob.core.windows.net/about/IMG_20191207_173619.jpg",
      minorTitle: "Updated June 2021",
      info: [
        {
          text: "This following document sets forth the Privacy Policy for the JPernickety website, and alias', rooted to https://jpernickety.com.",
          header: false,
        },
        {
          text: "JPernickety is committed to providing you with the best possible customer service experience. JPernickety is bound by the Privacy Act 1988 (Cth), which sets out a number of principles concerning the privacy of individuals.",
          header: false,
        },
        { text: "Collection of your personal information", header: true },
        {
          text: "There are many aspects of the site which can be viewed without providing personal information, however, aspects including submitting a Contact Form, requires a name and/ or email address, which may be a personally identifiable piece of information.",
          header: false,
        },
        { text: "Sharing of your personal information", header: true },
        {
          text: "We may occasionally hire other companies to provide services on our behalf, including but not limited to handling customer support enquiries, processing transactions or customer freight shipping. Those companies will be permitted to obtain only the personal information they need to deliver the service. JPernickety takes reasonable steps to ensure that these organisations are bound by confidentiality and privacy obligations in relation to the protection of your personal information.",
          header: false,
        },
        { text: "Use of your personal information", header: true },
        {
          text: "For each visitor to reach the site, we expressively collect the following non-personally identifiable information, including but not limited to browser type, version and language, operating system, pages viewed while browsing the Site, page access times and referring website address. This collected information is used solely internally for the purpose of gauging visitor traffic, trends and delivering personalised content to you while you are at this Site.",
          header: false,
        },
        {
          text: "Contact Form information may be stored in email format, with third parties (email provider) for the purpose of returning a reply, or keeping track of an enquiry. These are not personally stored by JPernickety, nor are they used for data processing, or for identifiable purposes.",
          header: false,
        },
        {
          text: "From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our privacy notice. If our information practices change at some time in the future we will use for these new purposes only, data collected from the time of the policy change forward will adhere to our updated practices.",
          header: false,
        },
        { text: "Changes to this Privacy Policy", header: true },
        {
          text: "JPernickety reserves the right to make amendments to this Privacy Policy at any time. If you have objections to the Privacy Policy, you should not access or use the Site.",
          header: false,
        },
        { text: "Accessing Your Personal Information", header: true },
        {
          text: "You have a right to access your personal information, subject to exceptions allowed by law. If you would like to do so, please let us know. You may be required to put your request in writing for security reasons. JPernickety reserves the right to charge a fee for searching for, and providing access to, your information on a per request basis.",
          header: false,
        },
        { text: "Contacting us", header: true },
        {
          text: "JPernickety welcomes your comments regarding this Privacy Policy. If you have any questions about this Privacy Policy and would like further information, please contact us by any of the following means during business hours Monday to Friday.",
          header: false,
        },
        { text: "E-mail: contact@elliotmoule.com", header: false },
        {
          text: "Contact Form: Form found the 'Contact' page: 'https://jpernickety.com/contact'.",
          header: false,
          style: "left",
        },
      ],
    };
  },
  created() {
    _core.setIsWait(false);
    this.isMobile = _core.getIsMobile();
  },
};
</script>

<style scoped>
.main-policy {
  position: relative;
  width: auto;
  height: 100%;
  background-color: whitesmoke;
  overflow: hidden;
  padding: 100px 0 0 100px;
}
.m-main-policy {
  position: relative;
  width: auto;
  height: 100%;
  background-color: whitesmoke;
  overflow: auto;
  padding: 50px;
}
.sub-policy {
  height: calc(100% - 80px);
  overflow-y: auto;
  padding: 0 50px;
}
.policy-text {
  width: 800px;
  text-align: justify;
}
.m-policy-text {
  text-align: justify;
}
</style>
