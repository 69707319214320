<template>
  <div class="app-content notfound-main">
    <div class="notfound-container">
      <p class="text-center notfound-text">
        404<br />
        Page Not Found<br />
      </p>
      <div v-bind:class="isMobile ? 'm-sprite-container' : 'sprite-container'">
        <div id="sprite-image"></div>
      </div>
      <p class="text-center">
        <b-button class="home-button" :to="{ name: 'home' }"
          >Let's Go Home</b-button
        >
      </p>
    </div>
  </div>
</template>

<script>
import * as _core from "../services/coreService";

export default {
  data() {
    return {
      isMobile: false,
    };
  },
  created() {
    this.isMobile = _core.getIsMobile();
  },
};
</script>

<style scoped>
.notfound-main {
  position: relative;
  width: auto;
  height: 100%;
  padding: 0 20px;
  background-color: whitesmoke;
  overflow: auto;
}
.notfound-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notfound-text {
  font-weight: bold;
  font-size: larger;
}
.home-button {
  background-color: rgb(0, 162, 255);
}
.sprite-container {
  margin: 100px;
}
.m-sprite-container {
  margin: 50px;
}
#sprite-image {
  height: 208px;
  width: 256px;
  background: url("https://jimjamphotographystorage.blob.core.windows.net/documents/404-notfound.png")
    0px 0px;
  animation: play 0.9s steps(19) infinite;
}
@keyframes play {
  100% {
    background-position: -4864px;
  }
}
</style>
