<template>
  <div ref="pageTop" class="portfolio-main">
    <div class="box" v-for="(image, index) in galleryImages" :key="index">
      <div class="boxInner">
        <img
          class="coverDiv"
          :style="{
            backgroundImage:
              'url(' +
              (image.smallUrl && !isEmptyOrSpace(image.smallUrl)
                ? image.smallUrl
                : image.url) +
              ')',
          }"
          @click="clickImage(image.name)"
        />
      </div>
    </div>
    <b-modal
      v-model="modalDialog.show"
      centered
      no-stacking
      ok-only
      hide-footer
      hide-header
      size="xl"
    >
      <b-card-img :src="modalDialog.img"></b-card-img>
    </b-modal>
  </div>
</template>

<script>
import * as _core from "../services/coreService";
export default {
  name: "vGallery",
  data() {
    return {
      isMobile: false,
      current: null,
      allImages: [],
      modalDialog: {
        show: false,
        img: "",
        title: "",
        text: "",
      },
    };
  },
  computed: {
    galleryImages: function () {
      return this.allImages;
    },
  },
  watch: {
    $route(to) {
      this.current = to.params.id;
      this.getImages();
      this.$refs.pageTop.scrollTo(0, 0);
    },
  },
  methods: {
    getImages() {
      var id = this.$route.params.id;
      _core.getGalleryImages(id).then((response) => {
        var images = [];
        if (response !== null) {
          images = response;
        }

        this.allImages = images.images;
        _core.setIsWait(false);
      });
    },
    clickImage(name) {
      if (name === null || !name) {
        this.modalDialog.show = false;
        this.modalDialog.img = "";
      } else {
        let obj = this.galleryImages.find((x) => {
          return x.name === name;
        });
        this.modalDialog.img = obj.url;
        this.modalDialog.show = true;
        this.modalDialog.title = name;
        this.modalDialog.text = obj.name;
      }
    },
    isEmptyOrSpace(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
  },
  created() {
    _core.setIsWait(true);
  },
  mounted() {
    this.getImages();
  },
};
</script>

<style scoped>
.portfolio-main {
  position: relative;
  width: auto;
  height: 100%;
  padding: 0 20px;
  background-color: whitesmoke;
  overflow: auto;
}
.boxInner img:hover {
  opacity: 0.9;
}
.box {
  float: left;
  position: relative;
  width: 20%;
  padding-bottom: 15%;
}
.boxInner {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  overflow: hidden;
}
.coverDiv {
  padding-bottom: 70%; /* or what you want */
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eee;
  background-position: center center;
}
.boxInner img {
  width: 100%;
  cursor: -webkit-zoom-in;
  backface-visibility: hidden;
  position: relative;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  transform: rotate(0);
}
@media only screen and (max-width: 480px) {
  /* Smartphone view: 1 tile */
  .box {
    width: 100%;
    padding-bottom: 100%;
  }
}
@media only screen and (max-width: 650px) and (min-width: 481px) {
  /* Tablet view: 2 tiles */
  .box {
    width: 50%;
    padding-bottom: 35%;
  }
}
@media only screen and (max-width: 1050px) and (min-width: 651px) {
  /* Small desktop / ipad view: 3 tiles */
  .box {
    width: 33.3%;
    padding-bottom: 25%;
  }
}
@media only screen and (max-width: 1290px) and (min-width: 1051px) {
  /* Medium desktop: 4 tiles */
  .box {
    width: 25%;
    padding-bottom: 25%;
  }
}
</style>
