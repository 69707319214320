<template>
  <b-container class="contact-container">
    <b-row align-h="center" class="contact-main px-0">
      <b-col class="contact-text py-5 px-5">
        <h3>
          {{ header1 }}
        </h3>
        <hr class="horizontal-rule" />
        <h5>
          {{ header2 }}
        </h5>
        <p v-for="(p, index1) in text" :key="index1">
          {{ p }}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          no-body
          class="overflow-hidden my-5 mx-5"
          style="max-width: 650px"
          v-if="!isMobile"
        >
          <b-row no-gutters>
            <b-col md="4">
              <b-card-img
                :src="portraitImage"
                :alt="cardAlt"
                class="rounded-2 mx-2 my-2"
              ></b-card-img>
            </b-col>
            <b-col md="8">
              <b-card-body :title="cardTitle">
                <b-card-text>
                  <ul>
                    <li>{{ header2 }}</li>
                    <li v-for="(qual, index2) in qualifications" :key="index2">
                      {{ qual }}
                    </li>
                  </ul>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          :title="cardTitle"
          :img-src="portraitImage"
          :img-alt="cardAlt"
          img-top
          style="max-width: 20rem"
          class="mb-2 mx-5 my-5"
          v-else
        >
          <b-card-text>
            <ul>
              <li v-for="(qual, index) in qualifications" :key="index">
                {{ qual }}
              </li>
            </ul>
          </b-card-text>
        </b-card>
        <div class="footer" v-if="isMobile">
          <div>
            Copyright © {{ new Date().getFullYear() }} Jasmin Moule - All Rights
            Reserved.
          </div>
          <a
            target="_blank"
            href="https://www.elliotmoule.com"
            class="link-elliot"
            >Website by Elliot Moule</a
          >
          <div>Version: {{ version }}</div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import * as _core from "../services/coreService";

export default {
  name: "vAbout",
  components: {},
  data() {
    return {
      isMobile: false,
      portraitImage:
        "https://jimjamphotographystorage.blob.core.windows.net/about/IMG_20191207_173619.jpg",
      header1: "Who I Am",
      header2: "Based in Albury/ Wodonga",
      cardTitle: "Jasmin Moule",
      cardAlt: "Picture of Jasmin Moule",
      text: [
        "Hi! I'm Jasmin, and I'm a degree trained Photographer, who also has experience and training in Graphic Design.",
        "I've been working with Children for over ten years, first as a Surf Life Saver, and currently as a School Learning Support Officer.",
        "As a specialist children's, and children with special needs, photographer, I am experienced in getting the highest quality images of your children. No yelling, no fights, just fun and happy smiles. I would welcome the opportunity to work with you, and your kids.",
        "I also have a great love for animals, and would love the opportunity to meet your special pet, and get you some fantastic pictures to treasure.",
      ],
      qualifications: [
        "Bachelor's of Visual Arts (Photomedia)",
        "Graphic Artist",
        "School Learning Support Officer",
        "Specialist Photographer for Children, and Children with Special Needs",
      ],
    };
  },
  computed: {
    version() {
      return _core.getVersion();
    },
  },
  created() {
    _core.setIsWait(false);
    this.isMobile = _core.getIsMobile();
  },
};
</script>

<style scoped>
/* Desktop */
.contact-container {
  background-color: rgb(48, 47, 47) !important;
  padding: 0;
  margin: 0;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
.contact-main {
  width: auto;
  background-color: rgb(27, 27, 27) !important;
}
.contact-text {
  color: white;
}
.horizontal-rule {
  background-color: white;
}
.contact-text p {
  text-align: justify;
  padding: 5px 0;
}
.footer {
  height: 60px;
  width: 100%;
  font-size: 12px;
  color: white;
  text-align: center;
}
.link-elliot {
  color: rgb(131, 168, 248) !important;
}
</style>
