<template>
  <!-- Masthead-->
  <header class="masthead" v-if="!isMobile">
    <div class="container d-flex h-100 align-items-center">
      <div class="mx-auto text-center">
        <h1 class="mx-auto my-0 text-uppercase">Pernickety</h1>
        <h1 class="mx-auto my-0 text-uppercase photo">Photography</h1>
        <h2 class="text-white-50 mx-auto mt-2 mb-5">
          Welcome to the online presence of<br />Pernickety Photography.
        </h2>
        <b-button
          class="main-button"
          :to="{ name: 'gallery', params: { id: 'animals' } }"
          >View Animal Gallery</b-button
        >
      </div>
    </div>
  </header>
  <header class="masthead" v-else>
    <div class="container d-flex h-100 align-items-center">
      <div class="mx-auto text-center">
        <h1 class="mx-auto my-0 text-uppercase">Pernickety</h1>
        <h1 class="mx-auto my-0 text-uppercase photo">Photography</h1>
        <h2 class="text-white-50 mx-auto mt-2 mb-5">
          Welcome to the online presence of<br />Pernickety Photography.
        </h2>
        <b-button
          class="main-button"
          :to="{ name: 'gallery', params: { id: 'animals' } }"
          >View Animal Gallery</b-button
        >
        <div>
          <b-button
            size="sm"
            v-if="isMobile"
            class="privacy-button"
            :to="{ name: 'privacy' }"
            >Privacy Policy</b-button
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import * as _core from "../services/coreService";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    _core.setIsWait(false);
  },
  created() {
    this.isMobile = _core.getIsMobile();
  },
};
</script>

<style scoped>
.masthead {
  width: auto;
  height: 100%;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.7) 75%,
      #000000 100%
    ),
    url("https://jimjamphotographystorage.blob.core.windows.net/landscape/sm_PXL_20210410_074546111-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
.masthead h1 {
  font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.8rem;
  background: linear-gradient(
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.5)
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
.masthead h2 {
  max-width: 20rem;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7) !important;
}
.photo {
  font-size: 1.5rem !important;
}
.main-button {
  background-color: rgb(0, 162, 255);
}
.button-text {
  color: white;
}
.privacy-button {
  background-color: black;
  margin: 10px;
}
</style>
